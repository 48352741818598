<template>
  <footer>
    <div v-motion-slide-visible-once-left class="logo">
      <router-link to="/"
        ><img src="@/assets/images/logo.svg" alt="Logo"
      /></router-link>
    </div>
    <img src="@/assets/images/line.svg" alt="Line" />
    <div v-motion-slide-visible-once-right class="menu">
      <ul>
        <li><router-link to="/">Domov</router-link></li>
        <li><router-link to="/ONas">O nás</router-link></li>
        <li><router-link to="/Brany">Brány</router-link></li>
        <li><router-link to="/Pohony">Pohony</router-link></li>
        <li><router-link to="/Kovovyroba">Kovovýrova</router-link></li>
        <li><router-link to="/Servis">Servis</router-link></li>
        <li>
          <router-link to="/CenovaPonuka">Cenová ponuka</router-link>
        </li>
        <li>
          <router-link to="/Kontakt">Kontakt</router-link>
        </li>
        <li>
          <router-link to="/ZarucnePodmienky">Záruka</router-link>
        </li>
        <li>
          <router-link to="/GDPR">GDPR</router-link>
        </li>
      </ul>
    </div>
  </footer>
  <div class="small-footer">
    <p v-motion-slide-visible-once-left :delay="500">
      Všetky práva vyhradené &copy; {{ date }}
    </p>
    <p v-motion-slide-visible-once-right :delay="500">
      Web by <a target="_blank" href="https://www.luboskukla.sk">Ľuboš Kukla</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
// IMPORT COLORS
@import "@/assets/sass/_colors.scss";

footer {
  background: url("@/assets/images/logoBig.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: darken($bg-clr, 1%);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;

  padding: 2em 0;
  box-shadow: 0px -28px 20px rgb(24 24 24 / 12%);
}

ul {
  margin: 0;
  padding-left: 0;
  list-style: none;

  display: grid;
  grid-gap: 0.8em;
  grid-template-columns: 10em 10em;
  grid-auto-flow: dense;
}

li a {
  color: $txt-clr;
}

.logo {
  width: 20.8em;
  display: flex;
  justify-content: flex-end;
}

.logo a img {
  width: 11em;
}

.small-footer {
  background: darken($bg-clr, 4%);
  color: $txt-clr;
  display: flex;
  justify-content: space-between;
  padding: 0.3em 5%;

  a {
    color: $txt-clr;

    &:hover {
      text: {
        decoration: underline;
        underline-offset: 0.2em;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  footer {
    justify-content: space-around;
    gap: 0;
  }

  .logo,
  .menu {
    justify-content: center;
    width: 50%;
    display: flex;

    ul {
      grid-template-columns: 9em 4em;
    }
  }
}

@media screen and (max-width: 550px) {
  footer {
    flex-direction: column;
    gap: 1em;

    & > img {
      transform: rotate(0deg);
      height: 1px;
      width: 80%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 450px) {
  .small-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;

    & p:first-child {
      margin: 0.8em auto 0.5em;
    }
    & p:last-child {
      margin: 0.5em auto 0.8em;
    }
  }
}

@media screen and (max-width: 400px) {
  footer > img {
    width: 90%;
  }
}
</style>
