<template>
  <nav>
    <div class="logo">
      <router-link to="/"
        ><img
          v-motion-slide-top
          :delay="500"
          src="@/assets/images/logo.svg"
          alt=""
      /></router-link>
    </div>
    <div v-motion-slide-top :delay="500" class="mobile hamburger-dropdown">
      <div
        @click="toggleOpen"
        :class="{ opened: isActive }"
        class="hamburger-lines"
      >
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
    </div>
    <div class="navigation pc">
      <div class="pc">
        <ul>
          <li v-motion-pop><router-link to="/">Domov</router-link></li>
          <li v-motion-pop :delay="100">
            <router-link to="/ONas">O nás</router-link>
          </li>
          <li v-motion-pop :delay="200">
            <router-link to="/Brany">Brány</router-link>
          </li>
          <li v-motion-pop :delay="300">
            <router-link to="/Pohony">Pohony</router-link>
          </li>
          <li v-motion-pop :delay="400">
            <router-link to="/Kovovyroba">Kovovýrova</router-link>
          </li>
          <li v-motion-pop :delay="500">
            <router-link to="/Servis">Servis</router-link>
          </li>
          <li v-motion-pop :delay="600">
            <router-link to="/CenovaPonuka">Cenová ponuka</router-link>
          </li>
          <li v-motion-pop :delay="700">
            <router-link to="/Kontakt">Kontakt</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="mobile">
    <div :class="{ opened: isActive }" class="pc menu mobile-navigation">
      <ul>
        <li @click="toggleOpen">
          <router-link to="/">Domov</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/ONas">O nás</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/Brany">Brány</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/Pohony">Pohony</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/Kovovyroba">Kovovýrova</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/Servis">Servis</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/CenovaPonuka">Cenová ponuka</router-link>
        </li>
        <li @click="toggleOpen">
          <router-link to="/Kontakt">Kontakt</router-link>
        </li>
        <!-- <a href="https://www.hg-solution.de" class="language">
          <p>DE</p>
          <img src="@/assets/images/germany-flag-icon.svg" alt="" />
        </a> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggleOpen: function () {
      this.isActive = !this.isActive;
      // some code to filter users
    },
  },
};
</script>

<style lang="scss" scoped>
// IMPORT COLORS
@import "@/assets/sass/_colors.scss";

nav,
.mobile-navigation {
  background: darken($bg-clr, 1%);
  padding: 1.5em 3%;
  z-index: 100;
  width: 96%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;

  .logo {
    width: 20%;
    display: flex;
    align-items: center;

    a img {
      width: 90%;
    }
  }

  .navigation {
    width: 80%;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    gap: 3.5%;
    font-size: 0.95em;
    padding-right: 4%;
    padding-left: 0;

    li a {
      text-decoration: none;
      color: $txt-clr;
      position: relative;
    }
  }
}

.mobile-navigation {
  width: 100%;
  margin-top: 6em;
  ul {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1em 10%;

    li {
      line-height: 2em;
      font-size: 1.15em;
    }
    li:not(:first-child) {
      border-top: $bg-clr 0.15em solid;
    }
  }

  .language {
    width: 100%;
    gap: 0.5em;
    justify-content: center;
  }
}
.router-link-active {
  font-weight: 600;
}

.navigation .router-link-active:after,
.mobile-navigation .router-link-active:after {
  content: "";
  position: absolute;
  width: 70%;
  display: block;
  height: 2px;
  background-color: darken($scnd-clr, 10%);
  bottom: -5px;
  animation: active 1s cubic-bezier(0, 0.52, 0.58, 1);
}

@keyframes active {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .pc {
    display: block;
  }

  .mobile {
    display: none;
    position: relative;
  }
}

@media screen and (max-width: 1000px) {
  nav {
    justify-content: space-between;
    flex-direction: row;
    padding: 1.5em 8%;
    width: 84%;
    .logo {
      width: 45%;
      justify-content: flex-start;
    }

    .hamburger-dropdown {
      justify-content: flex-end;
      width: 45%;
      display: flex;
      cursor: pointer;
    }

    .navigation {
      justify-content: center;
      width: 100%;
      display: flex;
      font-size: 120%;
    }
  }
  .pc {
    display: none !important;
  }

  .mobile {
    display: flex;
    justify-content: flex-end;
  }

  .opened {
    display: flex !important;
  }
}

/* Lines hamburger */
.hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: darken($scnd-clr, 10%);
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.opened.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.opened.hamburger-lines .line1 {
  transform: rotate(45deg);
}

.opened.hamburger-lines .line2 {
  transform: scaleY(0);
}

.opened.hamburger-lines .line3 {
  transform: rotate(-45deg);
}
</style>
