import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Domov",
    component: () => import("../views/Domov.vue"),
  },
  {
    path: "/ONas",
    name: "ONas",
    component: () => import("../views/ONas.vue"),
  },
  {
    path: "/Brany",
    name: "Brany",
    component: () => import("../views/Brany.vue"),
  },
  {
    path: "/GarazoveBrany",
    name: "Garážové brány",
    component: () => import("../views/brany/GarazoveBrany.vue"),
  },
  {
    path: "/PriemyselneRolovacieBrany",
    name: "Priemyselné rolovacie brány",
    component: () => import("../views/brany/PriemyselneRolovacieBrany.vue"),
  },
  {
    path: "/PriemyselneSekcionalneGarazoveBrany",
    name: "Priemyselné sekcionálne garážové brány",
    component: () =>
      import("../views/brany/PriemyselneSekcionalneGarazoveBrany.vue"),
  },
  {
    path: "/DvojkridlovePriemyselneBrany",
    name: "Dvojkrídlové priemyselné brány",
    component: () => import("../views/brany/DvojkridlovePriemyselneBrany.vue"),
  },
  {
    path: "/FolioveBrany",
    name: "dsadsa",
    component: () => import("../views/brany/FolioveBrany.vue"),
  },
  {
    path: "/VonkajsieBrany",
    name: "Rýchlobežné fóliové brány",
    component: () => import("../views/brany/VonkajsieBrany.vue"),
  },
  {
    path: "/ProtipoziarneBranyADvere",
    name: "Protipožiarne brány a dvere",
    component: () => import("../views/brany/ProtipoziarneBranyADvere.vue"),
  },
  {
    path: "/Pohony",
    name: "Pohony",
    component: () => import("../views/Pohony.vue"),
  },
  {
    path: "/PohonyPreGarazoveBrany",
    name: "PohonyPreGarazoveBrany",
    component: () => import("../views/pohony/PohonyPreGarazoveBrany.vue"),
  },
  {
    path: "/PohonyPreKridloveBrany",
    name: "PohonyPreKridloveBrany",
    component: () => import("../views/pohony/PohonyPreKridloveBrany.vue"),
  },
  {
    path: "/PohonyPrePosuvneBrany",
    name: "PohonyPrePosuvneBrany",
    component: () => import("../views/pohony/PohonyPrePosuvneBrany.vue"),
  },
  {
    path: "/ParkovacieZavory",
    name: "ParkovacieZavory",
    component: () => import("../views/pohony/ParkovacieZavory.vue"),
  },
  {
    path: "/ParkovacieZabrany",
    name: "ParkovacieZabrany",
    component: () => import("../views/pohony/ParkovacieZabrany.vue"),
  },
  {
    path: "/Kovovyroba",
    name: "Kovovyroba",
    component: () => import("../views/Kovovyroba.vue"),
  },
  {
    path: "/KovoveBranyPloty",
    name: "KovoveBranyPloty",
    component: () => import("../views/kovovyroba/KovoveBranyPloty.vue"),
  },
  {
    path: "/BalkonoveZabradlia",
    name: "BalkonoveZabradlia",
    component: () => import("../views/kovovyroba/BalkonoveZabradlia.vue"),
  },
  {
    path: "/SchodyAZabradlia",
    name: "SchodyAZabradlia",
    component: () => import("../views/kovovyroba/SchodyAZabradlia.vue"),
  },
  {
    path: "/OceloveDvere",
    name: "OceloveDvere",
    component: () => import("../views/kovovyroba/OceloveDvere.vue"),
  },
  {
    path: "/Pristresky",
    name: "Pristresky",
    component: () => import("../views/kovovyroba/Pristresky.vue"),
  },
  {
    path: "/InaVyroba",
    name: "InaVyroba",
    component: () => import("../views/kovovyroba/InaVyroba.vue"),
  },
  {
    path: "/Servis",
    name: "Servis",
    component: () => import("../views/Servis.vue"),
  },
  {
    path: "/CenovaPonuka",
    name: "CenovaPonuka",
    component: () => import("../views/CenovaPonuka.vue"),
  },
  {
    path: "/CenovaPonukaUspesna",
    name: "CenovaPonukaUspesna",
    component: () => import("../views/CenovaPonukaUspesna.vue"),
  },
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: () => import("../views/Kontakt.vue"),
  },
  {
    path: "/ZarucnePodmienky",
    name: "ZarucnePodmienky",
    component: () => import("../views/zarucnePodmienky.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
